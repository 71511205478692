'use_strict';

var LoadImages = function(elements, callback) {
  this.elements = elements;
  var self = this;
  var count;
  var imagesLoaded = 0;
  var images = [];

  this.init = function() {
    count = this.elements.length;
    for (let i in elements) {
      if (elements[i].complete) {
        self.imageLoaded();
      } else {
        elements[i].onload = function() { self.imageLoaded(); }
      }
      images.push(elements[i]);
    }
  }

  this.imageLoaded = function() {
    imagesLoaded++;
    if (count === imagesLoaded) {
      callback();
    }
  }

  this.init();
}
export default LoadImages